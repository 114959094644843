import React from "react";
import moment from 'moment';
import { formatAccount, formatDaterange, formatDatetime, formatDuration, formatEnergy, formatPrice } from '../Format';
const { Component } = React;

export default class DashboardLeftManagement extends Component {
    formId = 'dashboard-form';
    state = {
        initialized: false
    };
    params = {
        results: 5,
        sort_field: 'auth_datetime',
        sort_order: 'desc',
        daterange: moment().subtract(120, 'month').format('DD.MM.YYYY') + ' - ' + moment().format('DD.MM.YYYY'),
    };
    capabilities = [];
    userType = null;

    componentDidMount() {
        this.init();
    }

    init() {
        this.params.csrf_token = document.getElementById('csrf_token').value;
        let base = $('#dashboard');
        this.capabilities = base.data('capabilities').split(' ');
        this.userType = base.data('user-type');
        if (this.capabilities.indexOf('charge') !== -1 || this.capabilities.indexOf('charge-limited') !== -1) {
            $.post('/api/charges', this.params)
                .then(data => {
                    this.setState({
                        initialized: true,
                        charges: data.data
                    });
                });
        }
        if (this.capabilities.indexOf('order') !== -1 && this.userType === 'admin') {
            $.post('/api/orders', Object.assign({type: 'order'}, this.params))
                .then(data => {
                    this.setState({
                        initialized: true,
                        orders: data.data
                    });
                });
        }
        if (this.capabilities.indexOf('external') !== -1 && this.userType === 'admin') {
            $.post('/api/orders', Object.assign({type: 'external'}, this.params))
                .then(data => {
                    this.setState({
                        initialized: true,
                        externals: data.data
                    });
                });
        }
    }

    render() {
        if (!this.state.initialized) {
            return (
                <div className="text-center">
                    <i className="fa fa-spinner fa-pulse fa-fw"></i>
                </div>
            );
        }
        return (
            <div className="container container-noborder">
                <div className="row">
                    {this.renderCharges()}
                    {this.renderOrders()}
                    {this.renderExternals()}
                </div>
            </div>
        );
    }

    renderCharges() {
        if (!this.state.charges)
            return;
        if (!this.state.charges.length)
            return;
        let items = [];
        for (let i = 0; i < this.state.charges.length; i++) {
            items.push(this.renderCharge(this.state.charges[i]));
        }
        return (
            <div className="col-md-12">
                <h2>
                    <a href="/charges">Letzte Ladevorgänge</a>
                </h2>
                <table className="table table-bordered table-hover table-sm">
                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
        )
    }

    renderCharge(row) {
        return (
            <tr key={`charge-${row.id}`} onClick={this.linkUrl.bind(this, '/charge/' + row.id + '/show')}>
                <td>
                    {(row.status === 'auth') ? formatDatetime(row.auth_datetime) : formatDaterange(row.begin, row.end)}<br/>
                    <small>{(row.duration_raw !== undefined) ? formatDuration(row.duration_raw, true) : ''}</small>
                </td>
                <td>
                    {row.unit_uid}<br/>
                    <small>{row.locality}, {row.address}</small>
                </td>
                <td>
                    {formatAccount(row)}<br/>
                    <small>
                        {(row.token_type === 'static') ? ((row.token_source === 'fleet') ? ' Flotten-' : ' Nutzer-') : ''}
                        {this.formatTokenType(row.token_type).replace('-Karte', '')}
                    </small>
                </td>
                <td className="nowrap">
                    {row.status === 'complete' &&
                        <div>
                            <span>{formatPrice(row.value_total)}</span><br/>
                            <small>{formatEnergy(row.energy_raw)}</small>
                        </div>
                    }
                </td>
            </tr>
        )
    }


    renderOrders() {
        if (!this.state.orders)
            return;
        if (!this.state.orders.length)
            return;
        let items = [];
        for (let i = 0; i < this.state.orders.length; i++) {
            items.push(this.renderOrder(this.state.orders[i]));
        }
        return (
            <div className="col-md-12">
                <h2>
                    <a href="/orders">Letzte Bestellungen</a>
                </h2>
                <table className="table table-bordered table-hover table-sm">
                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
        )
    }

    renderOrder(row) {
        return (
            <tr key={`order-${row.id}`} onClick={this.linkUrl.bind(this, '/order/' + row.id + '/show')}>
                <td>
                    {formatDatetime(row.end)}
                </td>
                <td>
                    {row.cashpoint_name}
                </td>
                <td>
                    {row.account_identifier.substr(0, 4)} ... {row.account_identifier.substr(row.account_identifier.length - 2)}<br/>
                    <small>
                        {(row.token_type === 'static') ? ((row.token_source === 'fleet') ? ' Flotten-' : ' Nutzer-') : ''}
                        {this.formatTokenType(row.token_type).replace('-Karte', '')}
                    </small>
                </td>
                <td className="nowrap">
                    {row.status === 'complete' &&
                        <span>{formatPrice(row.value_total)}</span>
                    }
                </td>
            </tr>
        )
    }

    renderExternals() {
        if (!this.state.externals)
            return;
        if (!this.state.externals.length)
            return;
        let items = [];
        for (let i = 0; i < this.state.externals.length; i++) {
            items.push(this.renderExternal(this.state.externals[i]));
        }
        return (
            <div className="col-md-12">
                <h2>
                    <a href="/externals">Letzte ext. Abrechnungen</a>
                </h2>
                <table className="table table-bordered table-hover table-sm">
                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
        )
    }

    renderExternal(row) {
        return (
            <tr key={`external-${row.id}`} onClick={this.linkUrl.bind(this, '/order/' + row.id + '/show')}>
                <td>
                    {formatDatetime(row.end)}
                </td>
                <td>
                    {row.cashpoint_name && row.cashpoint_name.join(', ')}
                </td>
                <td>
                    {row.account_identifier.substr(0, 4)} ... {row.account_identifier.substr(row.account_identifier.length - 2)}<br/>
                    <small>
                        {(row.token_type === 'static') ? ((row.token_source === 'fleet') ? ' Flotten-' : ' Nutzer-') : ''}
                        {this.formatTokenType(row.token_type).replace('-Karte', '')}
                    </small>
                </td>
                <td className="nowrap">
                    {row.status === 'complete' && [
                        <span>{formatPrice(row.value_total)}</span>
                    ]}
                </td>
            </tr>
        )
    }

    formatTokenType(key) {
        if (!key)
            return '';
        if (key === 'static') {
            return 'RFID-Karte'
        }
        if (key === 'girocard') {
            return 'Girocard';
        }
        return key;
    }

    linkUrl(url) {
        window.location.href = url;
    }
}
