import { datepicker_options, daterangepicker_ranges, daterangepicker_locale } from '../Constants';
import React, { Component } from "react";
import moment from 'moment';


export default class InvoiceItemList extends Component {
    disable = false;

    defaultValues = {
        title: '',
        amount: 1,
        unit: 'piece',
        tax_rate: '0.15',
        value_single: '0'
    };

    state = {
        items: [Object.assign({}, this.defaultValues)]
    };

    container = 'invoice-item-list';

    constructor() {
        super();
        let items = JSON.parse(document.getElementById(this.container).getAttribute('data-items'));
        if (!items.length)
            items.push(Object.assign({}, this.defaultValues));
        this.state = {
            items: items
        };
        $('#date').datepicker(datepicker_options);
        $('#clearing_until').datepicker(datepicker_options);
        $('#delivery_daterange').daterangepicker({
            startDate: moment().subtract(1, 'month'),
            endDate: moment(),
            ranges: daterangepicker_ranges,
            locale: daterangepicker_locale
        });
    }

    addRow(i) {
        let items = [].concat(
            this.state.items.slice(0, i + 1),
            [Object.assign({}, this.defaultValues)],
            this.state.items.slice(i + 1)
        );

        this.setState({
            items: items
        });
    }

    removeRow(i) {
        let items = [];
        if (i) {
            items = [].concat(
                this.state.items.slice(0, i),
                this.state.items.slice(i + 1)
            );
        }
        else {
             items = this.state.items.slice(1);
        }
        this.setState({
            items: items
        })
    }

    render() {
        let result = [];
        for (let i = 0; i < this.state.items.length; i++) {
            result.push(this.renderRow(i, this.state.items[i]))
        }
        return result;
    }

    handleChange(i, key, value) {
        let items = this.state.items;
        items[i][key] = value;
        this.setState({
            items: items
        });
    }

    renderRow(i) {
        return [
            <div className="row row-form">
                {this.renderInput(12, i, 'title', 'Beschreibung')}
            </div>
                ,
            <div className="row row-form">
                <input type="hidden" value={i+1} name={`items-${i}-position`} />
                {this.renderInput(2, i, 'amount', 'Anzahl')}
                {this.renderSelect(2, i, 'unit', 'Einheit', [
                    <option value="piece">Stück</option>
                ])}
                {this.renderSelect(2, i, 'tax_rate', 'Steuersatz', [
                    <option value="0">0 %</option>,
                    <option value="0.07">7 %</option>,
                    <option value="0.19">19 %</option>
                ])}
                {this.renderInput(2, i, 'value_single', 'Einzelpreis', 'euro')}
                <div className="col-md-2 product-action-box">
                    <i className="fa fa-plus-circle fa-2x" aria-hidden="true" onClick={this.addRow.bind(this, i)}></i>
                    {this.state.items.length > 1 &&
                        <i className="fa fa-minus-circle fa-2x" aria-hidden="true" onClick={this.removeRow.bind(this, i)}></i>
                    }
                </div>
            </div>
        ]
    }

    renderInput(width, i, name, title, unit) {
        let error_p = [];
        if (this.state.items[i]._errors) {
            if (Object.keys(this.state.items[i]._errors).includes(name)) {
                error_p.push(
                    <p className="text-danger">{this.state.items[i]._errors[name]}</p>
                )
            }
        }
        return (
            <div className={`col-md-${width}${(error_p.length) ? ' error' : ''}`}>
                <label htmlFor={`items-${i}-title`}>{title}</label>
                {unit &&
                <span className={`unit unit-${unit}`}>
                    <input
                        className="form-control"
                        id={`items-${i}-${name}`}
                        name={`items-${i}-${name}`}
                        type="text"
                        value={this.state.items[i][name]}
                        onChange={(e) => this.handleChange(i, name, e.target.value)}
                    />
                </span>
                }
                {!unit &&
                    <input
                        className="form-control"
                        id={`items-${i}-${name}`}
                        name={`items-${i}-${name}`}
                        type="text"
                        value={this.state.items[i][name]}
                        onChange={(e) => this.handleChange(i, name, e.target.value)}
                    />
                }
                {error_p}
            </div>
        )
    }

    renderSelect(width, i, name, title, choices) {
        let error_p = [];
        if (this.state.items[i]._errors) {
            if (Object.keys(this.state.items[i]._errors).includes(name)) {
                error_p.push(
                    <p className="text-danger">{this.state.items[i]._errors[name]}</p>
                )
            }
        }
        return (
            <div className={`col-md-${width}${(error_p.length) ? ' error' : ''}`}>
                <label htmlFor={`items-${i}-title`}>{title}</label>
                <select
                        className="form-control"
                        id={`items-${i}-${name}`}
                        name={`items-${i}-${name}`}
                        value={this.state.items[i][name]}
                        onChange={(e) => this.handleChange(i, name, e.target.value)}
                    >
                    {choices}
                    </select>
                {error_p}
            </div>
        )
    }
}
