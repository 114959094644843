import React from "react";
import SearchTable from './SearchTable'
import { formatPrice } from "../Format";

export default class SearchTableAccount extends SearchTable {
    params = {
        sort_field: 'created',
        sort_order: 'desc',
        page: 1
    };
    apiUrl = '/api/accounts';
    formId = 'account-search-form';
    varPrefix = 'searchTableAccount';
    loadParamsRegExp = [
        /\/account\/(.*)/g
    ];

    sortDef = [
        { key: 'identifier', name: 'IBAN / Identifier' },
        { key: 'created', name: 'Erstellt' }
    ];

    colDef = [
        { text: 'Typ'},
        { sortField: 'identifier', text: 'IBAN / Identifier' },
        { text: 'Reputation' },
        { text: 'Status'},
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`account-${row.id}`}>
                {this.renderTableCellAccountType(row)}
                {this.renderTableCellAccount(row)}
                {this.renderTableCellReputation(row)}
                {this.renderTableCellStatus(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellAccountType(row) {
        return (
            <td>
                {this.formatAccountType(row.type)}<br/>
                seit {this.formatDate(row.created)}
            </td>
        );
    }

    renderTableCellAccount(row) {
        return (
            <td>
                <a href={'/account/' + row.account_id + '/show'}>{this.formatAccountIdentifier(row.type, row.identifier)}</a><br/>
                <small>BIC: {row.bic}</small>
            </td>
        )
    }

    renderTableCellReputation(row) {
        return (
            <td>
                {row.reputation_counter} Vorgänge<br/>
                {formatPrice(row.reputation_sum)} Umsatz
            </td>
        )
    }

    renderTableCellStatus(row) {
        return(
            <td>
                {this.renderStatusIcon('user', row.registered_ui)}
                {this.renderStatusIcon('ban', row.restricted)}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/account/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/account/${row.id}/edit`, 'pencil', 'bearbeiten', true)}
            </td>
        )
    }
}
