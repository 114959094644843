import React from "react";
import { formatDatetime } from "../Format";

const { Component } = React;

export default class SearchTable extends Component {
    state = {
        page: 1,
        data: [],
        resultCount: 0,
        initialized: false
    };
    itemsPerPage = 25;
    params = {
        sort_field: '',
        sort_order: '',
        page: 1
    };
    apiUrl = '';
    formId = '';
    varPrefix = '';
    loadParamsRegExp = [];
    operatorEnabled = false;
    capabilities = [];

    sortDef = [];
    colDef = [];
    submit_proceed = [];


    componentDidMount() {
        this.init();
    }

    init() {
        const csrfField = $('#csrf_token');
        csrfField.change((e) => this.params.csrf_token = e.target.value)
        this.loadParams();
        let form = $('#' + this.formId);
        this.operatorEnabled = parseInt(form.data('operator')) === 1;
        if (form.data('capabilities')) {
            this.capabilities = form.data('capabilities').trim().split(' ');
        }
        $('#' + this.formId + ' select').change((event) => this.formSubmit(event));
        document.getElementById(this.formId).onsubmit = (event) => this.formSubmit(event)
        $.getJSON('/api/common/csrf', (result) => {
            csrfField.val(result.csrf);
            csrfField.change();
            this.updateParams();
            this.updateData();
        });
    }

    updateData() {
        this.saveParams();
        let send = [];
        for (let key of Object.keys(this.params)) {
            if (Array.isArray(this.params[key])) {
                for (let i = 0; i < this.params[key].length; i++) {
                    send.push({
                        name: key,
                        value: this.params[key][i]
                    })
                }
            }
            else {
                send.push({
                    name: key,
                    value: this.params[key]
                })
            }
        }
        $.post(this.apiUrl, send)
            .then(data => {
                this.afterUpdate();
                if (data.status)
                    return;
                this.setState({
                    data: data.data,
                    aggs: data.aggs,
                    initialized: true,
                    page: this.params.page,
                    resultCount: data.count,
                    pageMax: (data.count) ? Math.ceil(data.count / this.itemsPerPage) : 1
                });
            });
    }

    afterUpdate() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        $(".selectpicker").selectpicker('refresh');
        $('.btn-icon').tooltip();
        $('[data-toggle="tooltip"]').tooltip();
    }

    loadParams() {
        if (!window.common.storageAvailable)
            return;
        if (!window.common.lastUrl)
            return;
        if (!this.loadParamsRegExpMatches())
            return;
        let params = localStorage.getItem(this.varPrefix + 'Data');
        if (!params)
            return;
        this.params = JSON.parse(params);
        for (let key of Object.keys(this.params)) {
            $('#' + key).val(this.params[key]);
        }
    }

    loadParamsRegExpMatches() {
        for (var i = 0; i < this.loadParamsRegExp.length; i++) {
            if (window.common.lastUrl.match(this.loadParamsRegExp[i]))
                return true;
        }
        return false;
    }

    formSubmit(event) {
        let active = event.submitter;
        if (active) {
            if (this.submit_proceed.includes(active.id)) {
                return;
            }
        }
        event.preventDefault();
        this.params.page = 1;
        this.updateParams();
        this.updateData();
    }

    daterangepickerSubmit(start, end, label) {
        this.params.page = 1;
        this.updateParams({
            daterange: start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY')
        });
        this.updateData();
    }

    updateParams(overwrite) {
        if (!overwrite) {
            overwrite = {};
        }
        let ids = [];
        $('#' + this.formId + ' select, #' + this.formId + ' input, #' + this.formId + ' textarea').each(function () {
            if ($(this).attr('id')) {
                ids.push($(this).attr('id'));
            }
        });
        for (let i = 0; i < ids.length; i++){
            let item = $('#' + ids[i]);
            if (item.attr('name') === 'csrf_token')
                continue;
            if (item.attr('name') === 'submit')
                continue;
            if (Object.keys(overwrite).includes(item.attr('name'))) {
                this.params[item.attr('name')] = overwrite[item.attr('name')];
                continue;
            }
            let sub = item.val();
            if (sub && sub !== '_default' && sub !== '_all') {
                this.params[item.attr('name')] = sub;
                continue;
            }
            delete this.params[item.attr('name')];
        }
        this.childUpdateParams();
    }

    childUpdateParams() {

    }

    saveParams() {
        if (!window.common.storageAvailable)
            return;
        localStorage.setItem(this.varPrefix + 'Data', JSON.stringify(this.params))
    }


    setPage(page) {
        if (page < 1 || page > this.state.pageMax)
            return;
        this.params.page = page;
        this.updateData();
    }

    setSort(field) {
        let sort_field_dom = $('#sort_field');
        let sort_order_dom = $('#sort_order');
        if (sort_field_dom.val() !== field) {
            sort_field_dom.val(field);
            sort_order_dom.val('asc');
        }
        else if (sort_order_dom.val() === 'asc') {
            sort_order_dom.val('desc');
        }
        else {
            sort_order_dom.val('asc');
        }
        $(".selectpicker").selectpicker('refresh');
        this.updateParams();
        this.updateData();
    }

    render() {
        if (!this.state.initialized) {
            return (
                <div className={'search-table-loading'}>
                    ... wird geladen ...
                </div>
            );

        }
        return (
            <div className={'search-table'}>
                {this.renderStatusLineTop()}
                {this.renderTable()}
                {this.renderStatusLineBottom()}
            </div>
        )
    }

    renderStatusLineTop() {
        return (
            <div className="row">
                <div className="col-md-12 search-table-result-header">
                    <div className="d-flex justify-content-between bd-highlight">
                        {this.renderStatusLineText()}
                        <div className="d-flex justify-content-end">
                            {this.renderPagination()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderStatusLineBottom() {
        return (
            <div className="row">
                <div className="col-md-12 search-table-result-footer">
                    <div className="d-flex justify-content-end">
                        {this.renderPagination()}
                    </div>
                </div>
            </div>
        )
    }

    renderStatusLineText() {
        let sort_list = [];
        for (let i = 0; i < this.sortDef.length; i++) {
            if (!this.operatorEnabled && this.sortDef[i].key === 'operator')
                continue;
            sort_list.push(
                <option key={this.sortDef[i].key} value={this.sortDef[i].key}>{this.sortDef[i].name}</option>
            )
        }
        return (
            <div className="d-flex justify-content-start search-table-result-header-text">
                <span>
                    {this.state.resultCount} Ergebnis{this.state.resultCount === 1 ? '' : 'se'}
                </span>
                <select
                    id="sort_order"
                    name="sort_order"
                    onChange={(event) => this.formSubmit(event)}
                    className="search-table-sort  selectpicker"
                    data-width="fit"
                    value={this.params.sort_order}
                >
                    <option value="asc">aufsteigend</option>
                    <option value="desc">absteigend</option>
                </select>
                <span>
                    sortiert nach
                </span>
                <select
                    id="sort_field"
                    name="sort_field"
                    onChange={(event) => this.formSubmit(event)}
                    className="search-table-sort selectpicker"
                    data-width="fit"
                    data-showicon="false"
                    value={this.params.sort_field}
                >
                    {sort_list}
                </select>
                {this.renderAdditionalStatusLineText()}
            </div>
        )
    }

    renderAdditionalStatusLineText() {}

    renderTable() {
        return(
            <div className="row">
                <div className="col-md-12">
                    <table className="table table-striped table-bordered">
                        <thead>
                        {this.renderTableHead()}
                        </thead>
                        <tfoot>
                        {this.renderTableHead()}
                        </tfoot>
                        {this.renderTableRows()}
                    </table>
                </div>
            </div>
        )
    }

    renderTableHead() {
        let cols = [];
        for (let i = 0; i < this.colDef.length; i++) {
            if (!this.operatorEnabled && this.colDef[i].text === 'Betreiber')
                continue;
            let attrib = {};
            let classes = [];
            if (this.colDef[i].sortField) {
                classes.push('sortable');
                attrib.onClick = this.setSort.bind(this, this.colDef[i].sortField);
                if (this.params.sort_field === this.colDef[i].sortField) {
                    classes.push('active');
                    classes.push(this.params.sort_order);
                }
            }
            cols.push(
                <th key={`table-header-${i}`} {...attrib} className={classes.join(' ')}>{this.colDef[i].text}</th>
            )
        }
        return(
            <tr>
                {cols}
            </tr>
        )
    }

    renderPagination() {
        return(
            <nav aria-label="pagination">
                <ul className="pagination justify-content-end">
                    <li className={'page-item' + (this.state.page === 1 ? ' disabled' : '')}>
                        <a className="page-link" href="#" aria-label="first" onClick={this.setPage.bind(this, 1)}>
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li className={'page-item' + (this.state.page === 1 ? ' disabled' : '')}>
                        <a className="page-link" href="#" aria-label="previous" onClick={this.setPage.bind(this, this.state.page - 1)}>
                            <span aria-hidden="true">&lsaquo;</span>
                        </a>
                    </li>
                    <li className="page-item disabled">
                        <span className="page-link">{this.state.page}/{this.state.pageMax}</span>
                    </li>
                    <li className={'page-item' + (this.state.page >= this.state.pageMax ? ' disabled' : '')}>
                        <a className="page-link" href="#" aria-label="next" onClick={this.setPage.bind(this, this.state.page + 1)}>
                            <span aria-hidden="true">&rsaquo;</span>
                        </a>
                    </li>
                    <li className={'page-item' + (this.state.page >= this.state.pageMax ? ' disabled' : '')}>
                        <a className="page-link" href="#" aria-label="last" onClick={this.setPage.bind(this, this.state.pageMax)}>
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
        )
    }

    renderTableRows() {
        let rows = [];
        for (let i = 0; i < this.state.data.length; i++) {
            rows.push(this.renderTableRow(this.state.data[i]))
        }
        return (
            <tbody>
            {rows}
            </tbody>
        )
    }

    renderStatusIcon(icon, condition, tooltip) {
        if (!condition)
            return '';
        return(
            <i style={{marginRight: '5px'}} className={`fa fa-${icon}`} aria-hidden="true" data-toggle='tooltip' data-placement='top' title={tooltip}></i>
        )
    }

    renderTableCellCreated(row, full) {
        return(
            <td>
                {(full) ? this.formatDateTime(row.created) : this.formatDate(row.created)}
            </td>
        )
    }

    renderTableCellAccountType(row) {
        return (
            <td>{this.formatAccountType(row.type)}</td>
        );
    }

    renderTableCellAccount(row) {
        return (
            <td>
                <a href={'/account/' + row.account_id + '/show'}>{this.formatAccountIdentifier(row.account_type, row.account_identifier)}</a><br/>
                <small>BIC: {row.account_bic}</small>
            </td>
        )
    }

    renderTableCellOperator(row) {
        if (!this.operatorEnabled)
            return;
        return(
            <td><a href={'/operator/' + row.operator.id + '/show'}>{row.operator.name}</a></td>
        )
    }

    renderTableCellActionIcon(url, icon, label, condition) {
        if (!condition)
            return '';
        return(
            <a href={url} className="btn btn-default btn-icon" data-toggle="tooltip" data-placement="top" title={label}>
                <i className={`fa fa-${icon}`} aria-hidden="true"></i>
            </a>
        )
    }

    formatDate(date) {
        if (!date)
            return '';
        return date.substr(8, 2) + '.' + date.substr(5, 2) + '.' + date.substr(0, 4);
    }

    formatDateTime(datetime) {
        if (!datetime)
            return '';
        return formatDatetime(datetime);
    }

    formatAccountType(account_type) {
        if (account_type === 'standard') {
            return 'Bank-Konto';
        }
        if (account_type === 'virtual') {
            return 'Virtuelles Konto';
        }
        if (account_type === 'setup') {
            return 'Konto im Aufbau';
        }
        if (account_type === 'creditcard') {
            return 'Kreditkarten-Konto';
        }
        return account_type;
    }

    formatAccountIdentifier(type, identifier, name) {
        if (type === 'betterpayment_web' && name)
            return name;
        if (!identifier)
            return '';
        if (type === 'standard') {
            return identifier.replace(/(\S{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
        }
        return identifier;
    }

    formatUnitUid(value) {
        if(!value) {
            return ''
        }
        if (value.substr(0, 2) === 'DE' && value.indexOf('*') === -1) {
            return value.substr(0, 2) + ' ' + value.substr(2, 3) + ' ' + value.substr(5, 1) + ' ' + value.substr(6);
        }
        return value;
    }

    formatTokenType(key) {
        if (key === 'static') {
            return 'RFID-Karte';
        }
        if (key === 'generic') {
            return 'Nutzerkennung';
        }
        if (key === 'girocard') {
            return 'Girocard';
        }
        if (key === 'emobilitycard') {
            return 'Sichere RFID-Karte';
        }
        return '';
    }
}
