import React from "react";
import { requestGet } from "../Common";
import { Typeahead } from 'react-bootstrap-typeahead';

const { Component } = React;

export default class NewOperatorSelector extends Component {
    state = {
        enableSelector: false,
        error: false,
        extended: false,
        selectedOperatorId: null,
        operators: []
    }
    apiUrl = '/api/operators/reduced'



    componentDidMount() {
        this.setState({
            enableSelector: this.props.enableSelector
        })

    }

    extendData() {
        requestGet(this.apiUrl + ((this.props.operatorCapability) ? '?operator-capability=' + this.props.operatorCapability : ''))
            .then((data) => {
                if (data.status) {
                    return this.setState({
                        error: true
                    });
                }
                this.setState({
                    operators: data.data,
                    extended: true
                })
            })
    }

    setSingleSelections(data) {
        if (!data || !data.length || !data[0].id) {
            return this.setState({
                selectedOperatorId: null
            });
        }
         return this.setState({
            selectedOperatorId: data[0].id
        });
    }

    render() {
        if (!this.props.enableSelector) {
            return (
                <a href={this.props.newUrl} className="btn btn-default btn-icon" data-toggle="tooltip" title={this.props.title}>
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </a>
            )
        }

        return (
            <div>
                <span
                    className="new-button-btn"
                    data-toggle="tooltip"
                    title={this.props.title}
                    onClick={this.extendData.bind(this)}
                >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </span>
                {this.renderExtended()}
                {this.renderSelected()}
            </div>
        )
    }

    renderExtended() {
        if (!this.state.extended)
            return;
        return(
            <div style={{ display: 'inline-block', marginRight: '2px'}}>
                <Typeahead
                    id="new-button-operator"
                    labelKey="name"
                    onChange={this.setSingleSelections.bind(this)}
                    options={this.state.operators}
                    placeholder="Betreiber wählen"
                    emptyLabel="Keine Ergebnisse"
                />
            </div>
        )
    }

    renderSelected() {
        if (!this.state.selectedOperatorId)
            return;
        return (
            <a
                href={`${this.props.newUrl}?operator=${this.state.selectedOperatorId}`}
                className="new-button-btn"
            >
                <i className="fa fa-check" aria-hidden="true"></i>
            </a>
        )
    }
}
