import React from "react";
import StatisticsGraph from "./StatisticsGraph";
import {formatPrice} from "../Format";

export default class DashboardStatistics extends StatisticsGraph {

    apiUrl = '/api/charges/day';
    formId = 'statistics-week-form';
    varPrefix = 'statisticsTransferDay';
    formEnabled = false;

    chartjs_data = {
        "labels": [],
        "datasets": [
            {
                "label": "Umsatz [€]",
                "data": [],
                "fill": false,
                "borderWidth": 1,
                "backgroundColor": "rgba(54, 162, 235, 0.2)",
                "borderColor": "rgb(54, 162, 235)"
            }
        ]
    };

    chartjs_options = {
        scales: {
            yAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: 'Umsatz'
                    },
                    ticks: {
                        beginAtZero: true,
                    }
                }
            ],
            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: 'Tag'
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => this.renderTooltipLabel(tooltipItem, data)
            }
        }
    };

    renderTooltipLabel(tooltipItem, data) {
        return formatPrice(tooltipItem.yLabel);
    }

    prepareData() {
        let data = [];
        let label = [];
        if (!this.state.aggs.auth_datetime)
            return data;
        let current_date = this.default.start;
        while (current_date < this.default.end) {
            label.push(current_date.format('DD.MM.'));
            current_date = current_date.add(1, 'day');
            let found = false;
            for (let i = 0; i < this.state.aggs.auth_datetime.length; i++) {
                if (current_date.format('YYYY-MM-DD') === this.state.aggs.auth_datetime[i].key_as_string.substr(0, 10)) {
                    data.push(this.state.aggs.auth_datetime[i].sales.value);
                    found = true;
                }
            }
            if (!found)
                data.push(0);
        }
        this.chartjs_data.labels = label;
        return data;
    }

    renderTitle() {
        return (
            <h2>
                <a href="/charges">Ihre Ladevorgänge</a>
            </h2>
        )
    }
}