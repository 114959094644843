import React from "react";
import SearchTable from './SearchTable'

export default class SearchTableCashpoint extends SearchTable {
    params = {
        sort_field: 'name',
        sort_order: 'asc',
        page: 1
    };
    formId = 'cashpoint-search-form';
    varPrefix = 'searchTableCashpoint';
    loadParamsRegExp = [
        /\/cashpoint\/client\/(.*)/g
    ];

    apiUrl = '/api/cashpoints';
    sortDef = [
        { key: 'name', name: 'Name' },
        { key: 'created', name: 'Erstellungszeitpunkt' },
        { key: 'modified', name: 'Änderungszeitpunkt' }
    ];

    colDef = [
        { sortField: 'name', text: 'Name' },
        { text: 'Betreiber' },
        { text: 'Ort' },
        { text: 'Aktionen' }
    ];
    
    renderTableRow(row) {
        return (
            <tr key={`cashpoint-${row.id}`}>
                {this.renderTableCellName(row)}
                {this.renderTableCellOperator(row)}
                {this.renderTableCellLocation(row)}
                {this.renderTableCellActions(row)}
            </tr>
        )
    }

    renderTableCellName(row) {
        return(
            <td>
                {row.name}
            </td>
        )
    }

    renderTableCellLocation(row) {
        return (
            <td>
                {row.name}
            </td>
        )
    }

    renderTableCellActions(row) {
        return(
            <td>
                {this.renderTableCellActionIcon(`/cashpoint/${row.id}/show`, 'info', 'anzeigen', true)}
                {this.renderTableCellActionIcon(`/cashpoint/${row.id}/edit`, 'edit', 'bearbeiten', this.capabilities.includes('edit'))}
                {this.renderTableCellActionIcon(`/cashpoint/${row.id}/delete`, 'trash', 'löschen', this.capabilities.includes('delete'))}
            </td>
        )
    }
}
