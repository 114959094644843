import {Decimal} from 'decimal.js';
import moment from 'moment';
import 'moment-timezone';

export const formatAccount = (row) => {
    if (!row.account_identifier || !row.account_type)
        return '';
    return row.account_identifier.substr(0, 4) + '...' + row.account_identifier.substr(row.account_identifier.length - 2);
};

export const formatPrice = (value) => {
    if (!value) {
        return '0,00 €'
    }
    if (value || value === 0) {
        value = new Decimal(value);
        value = value.toFixed(2);
        return value.replace('.', ',') + ' €';
    }
    return value;
};

export const formatPercentage = (value) => {
    if (!value)
        return '0 %';
    return String(parseInt(value)) + ' %';
}

export const formatEnergy = (value, include_unit) => {
    if (include_unit === undefined)
        include_unit = true;
    if (!value)
        return '0' + ((include_unit ? ' kWh' : ''));
    return Decimal(value).div(1000).toFixed(1).replace('.', ',') + ((include_unit ? ' kWh' : ''));
};

export const formatDuration = (value, short, minimal) => {
    if (value === undefined)
        value = 0;
    if (short === undefined)
        short = false;
    if (minimal === undefined)
        minimal = false;
    let houres = parseInt(Math.floor(value / 60 / 60));
    let minutes = parseInt(Math.floor((value / 60) - (houres * 60)));
    let result = String(houres) + ':' + zerofy(minutes);
    if (short)
        return result + ' Std.';
    let seconds = parseInt(Math.floor(value - (houres * 60 * 60) - (minutes * 60)));
    if (!minimal)
        return result + ':' + zerofy(seconds) + ' Stunden';
    if (value < 60)
        return String(seconds) + 's';
    if (value < 3600)
        return String(minutes) + ':' + zerofy(seconds) + 'm';
    return result + ':' + zerofy(seconds) + 'h';
};

export const formatDurationByTimestamps = (sooner, later, short) => {
    return formatDuration((new Date(later) - new Date(sooner)) / 1000, short)
};

export const zerofy = (value) => {
    if (value < 10)
        return '0' + String(value);
    return String(value);
};

export const removeZ = (value) => {
    if (!value)
        return value;
    if (value.slice(-1) === 'Z')
        return value.slice(0, -1);
    return value;
};

export const formatDatetime = (value, empty, utc) => {
    if (!value) {
        if (empty)
            return empty;
        return '';
    }
    return moment.utc(removeZ(value)).tz('Europe/Berlin').format('DD.MM., HH:mm:ss');
};

export const formatDaterange = (begin, end) => {
    let begin_date = moment.utc(removeZ(begin)).tz('Europe/Berlin');
    let end_date = moment.utc(removeZ(end)).tz('Europe/Berlin');
    if (end && begin_date.format('YYYY-MM-DD') === end_date.format('YYYY-MM-DD')) {
        return begin_date.format('DD.MM., HH:mm') + ' - ' + end_date.format('HH:mm');
    }
    return formatDatetime(begin) + ' - ' + formatDatetime(end, '...');
};

export const formatNumber = (value) => {
    if (!value)
        return 0;
    return value.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};